<template>
  <div>
    <div v-if="isLoading">
      <OtherLoading />
    </div>
    <div v-else>
      <HeaderPanel
        title="REDEEM"
        :filter="filter"
        placeholder="Search Redeem name"
        @search="handleSearch"
        @sidebar="sidebarFilter"
        :hasDropdown="false"
        :hasExport="true"
        @export="exportExcel"
        routerPath="/redeem/detail/0"
      />

      <div class="bg-white border-red p-2 p-lg-3 mt-3">
        <b-row>
          <b-col>
            <b-table
              responsive
              striped
              hover
              :fields="fields"
              :items="items"
              :busy="isBusy"
              show-empty
              empty-text="No matching records found"
            >
              <template v-slot:cell(redeem_Name)="data">
                <div class="text-left pl-2">
                  <b-button variant="link" class="px-1 py-0">
                    <router-link :to="'/redeem/detail/' + data.item.id">
                      <u>{{ data.item.redeem_Name }}</u>
                    </router-link>
                  </b-button>
                </div>
              </template>
              <template v-slot:cell(total_Redeemable)="data">
                <span class="m-0" v-if="data.item.total_Redeemable">
                  {{ data.item.total_Redeemable | numeral("0,0") }}
                </span>
                <span v-else>Unlimited Rights</span>
              </template>
              <template v-slot:cell(valid_From)="data">
                <div>{{ data.item.valid_From | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ data.item.valid_From | moment("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(valid_To)="data">
                <div>{{ data.item.valid_To | moment($formatDateNew) }}</div>
                <div class="time-color">
                  {{ data.item.valid_To | moment("HH:mm:ss") }}
                </div>
              </template>
              <template v-slot:cell(redeem_Status_name)="data">
                <div>
                  <span v-if="data.item.status == 1" class="text-success"
                    >Active</span
                  >
                  <span v-else class="text-danger">Inactive</span>
                </div>
              </template>
              <template v-slot:table-busy>
                <div class="text-center text-black my-2">
                  <b-spinner class="align-middle"></b-spinner>
                  <strong class="ml-2">Loading...</strong>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>

        <Pagination
          @handleChangeTake="handleSearch"
          :pageOptions="pageOptions"
          :filter="filter"
          :rows="rows"
          @pagination="pagination"
        />
      </div>
    </div>
    <SideBarFilter
      ref="sidebarFilter"
      :filter="filter"
      @searchAll="submitFilter"
      placeholder="Search Redeem name"
      @clearFilter="clearFilter"
    >
      <template v-slot:filter-option
        ><div class="mt-3">
          <div class="font-weight-bold mb-2">Type</div>
          <b-form-group class="mb-2">
            <b-form-checkbox
              v-for="(t, index) in typeOption"
              :key="index"
              :value="t.value"
              v-model="type"
              >{{ t.text }}</b-form-checkbox
            >
          </b-form-group>
        </div>
        <div>
          <div class="mt-3">
            <div class="font-weight-bold mb-2">Transaction Date (start)</div>
            <div class="input-container">
              <datetime
                type="datetime"
                :input-style="styleDatetime"
                v-model="filter.startDate"
                placeholder="DD/MM/YYYY (HH:MM)"
                format="dd/MM/yyyy (HH:mm)"
                value-zone="Asia/Bangkok"
                @close="changeStartDate"
                ref="transferDateStart"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.transferDateStart.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
          <div class="mt-3 mb-3">
            <div class="font-weight-bold mb-2">Transaction Date (end)</div>
            <div class="input-container">
              <datetime
                type="datetime"
                :input-style="styleDatetime"
                v-model="filter.endDate"
                placeholder="DD/MM/YYYY (HH:MM)"
                format="dd/MM/yyyy (HH:mm)"
                value-zone="Asia/Bangkok"
                ref="transferDateEnd"
                :min-datetime="filter.startDate"
              >
              </datetime>
              <div
                class="icon-primary text-right"
                @click="$refs.transferDateEnd.isOpen = true"
              >
                <font-awesome-icon
                  icon="calendar-alt"
                  class="pointer color-primary"
                  color="#B41BB4"
                />
              </div>
            </div>
          </div>
        </div>
      </template>
    </SideBarFilter>
  </div>
</template>

<script>
import OtherLoading from "@/components/loading/OtherLoading";
import HeaderPanel from "@/components/HeaderPanel";
import Pagination from "@/components/Pagination";
import SideBarFilter from "@/components/SideBarFilter";
export default {
  name: "RedeemIndex",
  components: {
    OtherLoading,
    HeaderPanel,
    Pagination,
    SideBarFilter,
  },
  data() {
    return {
      isLoading: true,
      fields: [
        {
          key: "redeem_Name",
          label: "Redeem Name / Description",
          class: "w-200",
        },
        {
          key: "total_Redeemable",
          label: "Exchangeable",
          class: "w-200",
        },
        {
          key: "valid_From",
          label: "Valid From",
          class: "w-200",
        },
        {
          key: "valid_To",
          label: "Valid To",
          class: "w-200",
        },
        {
          key: "redeem_Status_name",
          label: "Status",
        },
      ],
      items: [],
      isBusy: false,
      rows: 0,
      filter: {
        page: 1,
        search: "",
        status: [],
        start: 1,
        take: 10,
        redeemType: "",
        startDate: "",
        endDate: "",
        redeemStatus: "",
      },
      status: [],
      type: [],
      pageOptions: [
        { value: 10, text: "10 / page" },
        { value: 30, text: "30 / page" },
        { value: 50, text: "50 / page" },
        { value: 100, text: "100 / page" },
      ],
      showingTo: 10,
      sidebarShow: false,
      styleDatetime: {
        width: "100%",
        border: "none",
      },
      typeOption: [
        {
          text: "Single Product",
          value: 1,
        },
        {
          text: "Package",
          value: 2,
        },
      ],
      statusOption: [
        {
          text: "เปิดใช้งาน",
          value: 1,
        },
        {
          text: "ปิดใช้งาน",
          value: 0,
        },
      ],
      statusAll: false,
    };
  },
  computed: {
    selectStatusFilter() {
      if (this.status.length == 2) {
        return true;
      } else {
        return false;
      }
    },
  },
  async created() {
    await this.getList();
  },
  methods: {
    sidebarFilter() {
      this.$refs.sidebarFilter.show();
    },
    async getList() {
      this.isBusy = true;
      this.filter.start = this.filter.page;
      this.filter.redeemStatus = this.filter.status.toString();
      this.filter.redeemType = this.type.toString();
      await this.$store.dispatch("getListRedeem", this.filter);
      const data = this.$store.state.redeem.list;
      if (data.result == 1) {
        this.items = data.detail.data;
        this.rows = data.detail.recordsTotal;
      }
      this.isBusy = false;
      this.isLoading = false;
    },
    handleSearch() {
      this.filter.start = 1;
      this.filter.page = 1;
      this.getList();
    },
    pagination(page) {
      this.filter.page = page;
      this.filter.start = (this.filter.page - 1) * this.filter.take + 1;
      this.getList();
    },
    changeStartDate() {
      if (this.filter.startDate && this.filter.endDate) {
        this.filter.endDate =
          this.filter.startDate >= this.filter.endDate
            ? ""
            : this.filter.endDate;
      }
    },
    clearFilter() {
      this.filter = {
        page: 1,
        search: "",
        status: [],
        start: 1,
        take: 10,
        redeemType: "",
        startDate: "",
        endDate: "",
        redeemStatus: "",
      };
      this.status = [];
      this.type = "";
    },
    async submitFilter() {
      this.filter.page = 1;
      this.filter.start = 1;
      await this.getList();
      // this.$refs.filterSidebar.hide();
    },
    async exportExcel() {
      this.$bus.$emit("showLoading");
      const data = await this.axios.post(
        "/Redeem/export_redeem_list",
        this.filter,
        {
          responseType: "blob",
        }
      );

      var fileURL = window.URL.createObjectURL(new Blob([data.data]));
      var fileLink = document.createElement("a");
      var dateExcel = this.$moment().format("DDMMYYYYhhmmss");
      fileLink.href = fileURL;
      fileLink.setAttribute("download", `redeem-list-` + dateExcel + `.xlsx`);
      document.body.appendChild(fileLink);
      fileLink.click();

      this.$bus.$emit("hideLoading");
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep thead {
  background-color: var(--secondary-color);
  color: var(--font-color);
}
::v-deep .input-group-text {
  background-color: #fff;
  padding: 0 0.75rem;
}
.text-gray {
  color: #aaaaaa;
}
</style>
